export const API_ROUTER = {
  auth: {
    register: {
      method: "POST",
      url: "/auth/register",
    },
    logIn: {
      method: "POST",
      url: "/auth/login",
    },
    logOut: {
      method: "POST",
      url: "/auth/logout",
    },
    forgotPassword: {
      method: "POST",
      url: "/auth/password/forgot",
    },
    changePassword: {
      method: "PUT",
      url: "/app/password/change",
    },
    confirmPassword: {
      method: "POST",
      url: "/auth/password/confirm",
    },
    confirmEmail: {
      method: "POST",
      url: "/public/v1/email/confirm",
    },
  },
  user: {
    setUserAvatar: {
      method: "POST",
      url: "/app/users/avatar",
    },
    setUserData: {
      method: "PUT",
      url: "/app/users/profile",
    },
    getUserData: {
      method: "GET",
      url: "/api/v1/my/profile",
    },
    getUserReferrals: {
      method: "GET",
      url: "/app/referrals",
    },
    getUserDataByUrl: {
      method: "GET",
      url: "/public/users/{url}",
    },
    getNewUsersAroundYou: {
      method: "GET",
      url: "/app/users/{userUuid}/new-around-you",
    },
    search: {
      method: "GET",
      url: "/public/user/search",
    },
    saveAddress: {
      method: "PUT",
      url: "/app/users/address",
    },
  },
  publicLabels: {
    getCountries: {
      method: "GET",
      url: "/public/labels/countries",
    },
  },
  tournaments: {
    getTournamentsCommunity: {
      method: "GET",
      url: "public/v1/community/{communityId}/events/1/100",
    },
  },
};
