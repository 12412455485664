import React, { useState, useEffect } from "react";
import { API, API_ROUTER } from "../../../api";
import { FormattedMessage, injectIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import SuccessfulMessage from "../successful-message";
import store from "../../../redux/store";
import { COUNTRY_LIST } from "../../../config";
import s from "./auth.module.scss";

const Auth = (props) => {
  const { interfaceLang } = store.getState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countrie, setCountrie] = useState(null);
  const [pass, setPass] = useState("");
  const [statusMessage, setStatusMessage] = useState(false);
  const [textMessage, setTextMessage] = useState();
  const [checkbox1Status, setCheckbox1Status] = useState(false);
  const [checkbox2Status, setCheckbox2Status] = useState(false);

  const [validateForm, setValidateForm] = useState(false);

  //error
  const [err, setErr] = useState(false);
  const [errName, setErrName] = useState(
    <FormattedMessage id="authModal.err.title" tagName="label" />
  );
  const [errEmail, setErrEmail] = useState(
    <FormattedMessage id="authModal.err.title" tagName="label" />
  );
  const [errCountrie, setErrCountrie] = useState(
    <FormattedMessage id="authModal.err.title" tagName="label" />
  );
  const [errPass, setErrPass] = useState(
    <FormattedMessage id="authModal.err.title" tagName="label" />
  );
  const [errCheckbox1Status, setErrCheckbox1Status] = useState(false);

  const [nameDirty, setNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [countriesDirty, setCountriesDirty] = useState(false);
  const [passDirty, setPassDirty] = useState(false);

  const [countries, setCountries] = useState(COUNTRY_LIST);

  // useEffect(() => {
  //   API.request({ ...API_ROUTER.publicLabels.getCountries })
  //     .then((res) => setCountries(res))
  //     .catch((err) => console.log(err));
  // }, [countrie]);
  useEffect(() => {
    if (errName || errEmail || errCountrie || errPass) {
      setValidateForm(false);
    } else {
      setValidateForm(true);
    }
  }, [errName, errEmail, errCountrie, errPass]);

  const checkEmailData = (text) => {
    if (
      /[a-zA-Z]/.test(text) &&
      text.length > 3 &&
      text.includes("@") &&
      text.includes(".")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (checkEmailData(email) && countrie && name && pass && checkbox1Status) {
      const params = {
        ...API_ROUTER.auth.register,
        data: {
          /* referral: "UA-12345AA", */
          username: email,
          country: countrie,
          firstName: name,
          password: pass,
          /* psnId: "584d56434p5f", */
          gdpr: true,
          marketing: true,
          sourceUrl: window.location.href,
          //lang: interfaceLang,
        },
      };

      API.request(params, true)
        .then((res) => {
          console.log(res);
          setStatusMessage(true);
          setTextMessage(<SuccessfulMessage />);
        })
        .catch((err) => {
          // console.log(err, '===')
          // let info = JSON.stringify(err.data.errors)
          //   ?.replace(/{"/, "")
          //   ?.replace(/"}/, "")
          //   ?.replace(/"/, "")
          //   ?.replace(/"/, " ")
          //   ?.replace(/","/, " ")
          //   ?.replace(/":"/, ": ");
          // setStatusMessage(true);
          // //setTextMessage(`${err.data.message} ${info}`);
          // setTextMessage('Registration error');
          // setTimeout(() => {
          //   setStatusMessage(false);
          // }, 3000);
          setStatusMessage(true);
          setTextMessage(err.data.errors.username);
        });
    } else setErr(true);
  };
  const showCountries = (value) => {
    let optionArray = [
      <option value={null} selected>
        ...
      </option>,
    ];

    for (let key in value) {
      optionArray.push(<option value={key}>{value[key]}</option>);
    }
    return optionArray;
  };

  const nameHandler = (value) => {
    setName(value);
    if (value.length < 3) {
      setErrName(<FormattedMessage id="authModal.err.name" tagName="label" />);
    } else {
      setErrName("");
    }
  };
  const emailHandler = (value) => {
    setEmail(value);
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!re.test(String(value).toLowerCase())) {
      setErrEmail(
        <FormattedMessage id="authModal.err.email" tagName="label" />
      );
    } else {
      setErrEmail("");
    }
  };
  const countriesHandler = (value) => {
    console.log(value);
    setCountrie(value);
    if (value === "...") {
      setErrCountrie(
        <FormattedMessage id="authModal.err.title" tagName="label" />
      );
    } else {
      setErrCountrie("");
    }
  };
  const passHandler = (value) => {
    setPass(value);
    if (value.length < 5) {
      setErrPass(<FormattedMessage id="authModal.err.pass" tagName="label" />);
      if (!value) {
        setErrPass(
          <FormattedMessage id="authModal.err.title" tagName="label" />
        );
      }
    } else {
      setErrPass("");
    }
  };
  /* const checkbox1Handler=(value)=>{
      setCheckbox1Status(!value);
      if(!setCheckbox1Status){

      }
   } */

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;

      case "email":
        setEmailDirty(true);
        break;

      case "countries":
        setCountriesDirty(true);
        break;

      case "pass":
        setPassDirty(true);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      {statusMessage ? (
        <div className={s.message}>{textMessage}</div>
      ) : (
        <div>
          <div className={s.title}>
            <FormattedMessage id="authModal.signUp.title" tagName="label" />
          </div>
          <div className={s.subtitle}>
            <FormattedMessage id="authModal.signUp.subtitle" tagName="label" />
          </div>

          <div className={s.inputContainer}>
            <FormattedMessage
              id="global.forms.labels.firstName"
              tagName="label"
            />
            <span className={nameDirty && errName && `${s.err}`}>
              <input
                onBlur={(e) => blurHandler(e)}
                name="name"
                type="text"
                value={name}
                onChange={(e) => nameHandler(e.target.value)}
              />
            </span>
            {nameDirty && errName && (
              <span style={{ color: "red" }}>{errName}</span>
            )}
          </div>

          <div className={s.inputContainer}>
            <FormattedMessage id="global.forms.labels.email" tagName="label" />
            <span className={errEmail && emailDirty && `${s.err}`}>
              <input
                onBlur={(e) => blurHandler(e)}
                name="email"
                type="text"
                value={email}
                onChange={(e) => emailHandler(e.target.value)}
              />
            </span>
            {errEmail && emailDirty && (
              <span style={{ color: "red" }}>{errEmail}</span>
            )}
          </div>

          <div className={s.inputContainer}>
            <FormattedMessage
              id="global.forms.labels.country"
              tagName="label"
            />
            <span className={errCountrie && countriesDirty && `${s.err}`}>
              <select
                name="countries"
                onBlur={(e) => blurHandler(e)}
                onChange={(e) => countriesHandler(e.target.value)}
              >
                {showCountries(countries)}
              </select>
            </span>
            {errCountrie && countriesDirty && (
              <span style={{ color: "red" }}>{errCountrie}</span>
            )}
            {/* <div className={s.text}>
                     <FormattedMessage id="global.forms.labels.countryName" tagName="label" />
                  </div> */}
          </div>

          <div className={s.inputContainer}>
            <FormattedMessage
              id="global.forms.labels.password"
              tagName="label"
            />
            <span className={errPass && passDirty && `${s.err}`}>
              <input
                onBlur={(e) => blurHandler(e)}
                name="pass"
                type="password"
                value={pass}
                onChange={(e) => passHandler(e.target.value)}
              />
            </span>
            {errPass && passDirty && (
              <span style={{ color: "red" }}>{errPass}</span>
            )}
          </div>

          <div className={s.chexBoxContainer}>
            <input
              onBlur={(e) => blurHandler(e)}
              name="checkbox"
              type="checkbox"
              checked={checkbox1Status}
              onClick={() => setCheckbox1Status(!checkbox1Status)}
            />
            <span
              className={s.textContainer}
              onClick={() => setCheckbox1Status(!checkbox1Status)}
            >
              <FormattedMessage
                id="authModal.signUp.check1.full"
                tagName="label"
              />
            </span>
            <a
              target="_blanck"
              className={s.linkStyle}
              href="https://app.passport.gg/terms-of-use"
            >
              <FormattedMessage
                id="authModal.signUp.check1.linkTerms"
                tagName="label"
              />
            </a>
            {`, `}
            <a
              target="_blanck"
              className={s.linkStyle}
              href="https://app.passport.gg/privacy-policy"
            >
              <FormattedMessage
                id="authModal.signUp.check1.linkPrivacy"
                tagName="label"
              />
            </a>
            {`, `}
            <a
              target="_blanck"
              className={s.linkStyle}
              href="https://app.passport.gg/cookie-policy"
            >
              <FormattedMessage
                id="authModal.signUp.check1.linkCookie"
                tagName="label"
              />
            </a>
            <FormattedMessage
              id="authModal.signUp.check1.and"
              tagName="label"
            />
            <a target="_blanck" className={s.linkStyle} href="/rulebook-asia">
              <FormattedMessage
                id="authModal.signUp.check1.linkRulebook"
                tagName="label"
              />
              .
            </a>
          </div>
          {!!err && (
            <div className={s.errText}>
              <FormattedMessage id="authModal.err.title" tagName="label" />
            </div>
          )}

          <div className={s.chexBoxContainer}>
            <input
              type="checkbox"
              checked={checkbox2Status}
              onClick={() => setCheckbox2Status(!checkbox2Status)}
            />
            <span
              className={s.textContainer}
              onClick={() => setCheckbox2Status(!checkbox2Status)}
            >
              <FormattedMessage id="authModal.signUp.check2" tagName="label" />
            </span>
          </div>

          <div className={s.btnContainer}>
            <button
              className={
                !validateForm ? `${s.disabledBTN}` : `${s.btnRegister}`
              }
              onClick={onSubmit}
              disabled={!validateForm}
            >
              <FormattedMessage id="global.buttons.signUp" tagName="label" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Auth;
