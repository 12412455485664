import React from 'react';
import PropTypes from "prop-types";

export default function IconInstagramm({fill = 'white'}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1094 12C14.1094 13.1649 13.1649 14.1094 12 14.1094C10.8351 14.1094 9.89062 13.1649 9.89062 12C9.89062 10.8351 10.8351 9.89062 12 9.89062C13.1649 9.89062 14.1094 10.8351 14.1094 12Z" fill={fill}/>
            <path d="M15.5625 5.625H8.4375C6.8866 5.625 5.625 6.8866 5.625 8.4375V15.5625C5.625 17.1134 6.8866 18.375 8.4375 18.375H15.5625C17.1134 18.375 18.375 17.1134 18.375 15.5625V8.4375C18.375 6.8866 17.1134 5.625 15.5625 5.625ZM12 15.5156C10.0615 15.5156 8.48438 13.9385 8.48438 12C8.48438 10.0615 10.0615 8.48438 12 8.48438C13.9385 8.48438 15.5156 10.0615 15.5156 12C15.5156 13.9385 13.9385 15.5156 12 15.5156ZM16.0312 8.67188C15.6429 8.67188 15.3281 8.35712 15.3281 7.96875C15.3281 7.58038 15.6429 7.26562 16.0312 7.26562C16.4196 7.26562 16.7344 7.58038 16.7344 7.96875C16.7344 8.35712 16.4196 8.67188 16.0312 8.67188Z" fill={fill}/>
            <path d="M17.6719 0H6.32812C2.83887 0 0 2.83887 0 6.32812V17.6719C0 21.1611 2.83887 24 6.32812 24H17.6719C21.1611 24 24 21.1611 24 17.6719V6.32812C24 2.83887 21.1611 0 17.6719 0ZM19.7812 15.5625C19.7812 17.8887 17.8887 19.7812 15.5625 19.7812H8.4375C6.11133 19.7812 4.21875 17.8887 4.21875 15.5625V8.4375C4.21875 6.11133 6.11133 4.21875 8.4375 4.21875H15.5625C17.8887 4.21875 19.7812 6.11133 19.7812 8.4375V15.5625Z" fill={fill}/>
        </svg>
    )
}

IconInstagramm.propTypes = {
    fill: PropTypes.string
};