import styled from "styled-components";
import map from "../../img/map.svg";
import bonus01 from "./img/bonus-01.png";
import bonus02 from "./img/bonus-02.png";
import bonus03 from "./img/bonus-03.png";
import bonus04 from "./img/bonus-04.png";
import bonus05 from "./img/bonus-05.png";

//color tchema
import {
  mainBGColor,
  borderColor,
  bgColorLite,
  BTNRegistrationColor,
  BTNRegistrationBorder,
  bgColorText,
  PrimaryBackgroundGradientColor,
} from "../../total/const";

const DefaultSection = styled.div`
  background: #fff;
  overflow: hidden;
  .linkContainer * {
    display: inline;
  }
  a {
    text-decoration: underline;
    color: black;
  }

  h2 {
    font-weight: bold;
    font-size: 36px;
    color: #000;
    margin: 0 0 40px;
    position: relative;

    @media (max-width: 767px) {
      font-size: 21px;
      margin: 0 0 20px;
    }

    span {
      background: ${borderColor};
      padding: 0 6px;
      color: ${bgColorText};
    }

    .arrows {
      position: absolute;
      left: -50px;
      top: 16px;

      @media (max-width: 1199px) {
        display: none;
      }
    }
  }

  p,
  ul {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 20px;
    color: rgba(0, 0, 0, 0.6);

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  ul {
    li + li {
      margin-top: 8px;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  .center {
    margin-top: 7rem;
  }

  .row {
    margin: 0 -15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    @media (max-width: 965px) {
      display: flex;
      flex-direction: column-reverse;
      padding: 2rem;

      .imgLogo {
        grid-gap: 1rem;
        padding-left: 0;
        margin-top: 4rem;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      //height: 100%;
    }
  }

  .col {
    flex: 1;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .dots-decorator {
    position: relative;
    z-index: 0;
    padding: 10rem 0;

    @media (max-width: 867px) {
      padding: 4rem 0;
    }

    .dots {
      position: absolute;
      z-index: -1;

      @media (max-width: 1499px) {
        display: none;
      }
    }
  }
`;

export const Styled = {
  Video: styled.video`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,
  ButtonGradient: styled.button`
    border-radius: 4px;
    border: 0;
    background: ${PrimaryBackgroundGradientColor};
    height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 0 50px;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
  `,
  Button: styled.button`
    border-radius: 3px;
    border: ${BTNRegistrationBorder};
    background: ${BTNRegistrationColor};
    height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 0 50px;
    //cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    @media (max-width: 767px) {
      padding: 0 30px;
      height: 40px;
    }

    &:hover,
    &:focus {
      background: #d5910f;
      border-color: #d5910f;
    }

    &.login {
      border-color: ${borderColor};
      color: ${borderColor};
      background: transparent;

      &:hover,
      &:focus {
        background: ${borderColor};
        border-color: ${borderColor};
        color: #fff;
      }
    }
  `,
  Tournaments: styled.div`
    background: #fff;
    overflow: hidden;
    .linkContainer * {
      display: inline;
    }
    a {
      text-decoration: underline;
      color: black;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      color: #000;
      margin: 0 0 40px;
      position: relative;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 20px;
      }

      span {
        background: ${borderColor};
        padding: 0 6px;
        color: ${bgColorText};
      }

      .arrows {
        position: absolute;
        left: -50px;
        top: 16px;

        @media (max-width: 1199px) {
          display: none;
        }
      }
    }

    p,
    ul {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.5;
      margin: 0 0 20px;
      color: rgba(0, 0, 0, 0.6);

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    ul {
      li + li {
        margin-top: 8px;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    .center {
      margin-top: 7rem;
    }

    .row {
      margin: 0 -15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4rem;

      @media (max-width: 965px) {
        display: flex;
        flex-direction: column-reverse;
        padding: 2rem;

        .imgLogo {
          grid-gap: 1rem;
          padding-left: 0;
          margin-top: 4rem;
        }
      }
    }

    .col {
      flex: 1;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .dots-decorator {
      position: relative;
      z-index: 0;
      padding: 10rem 0;

      @media (max-width: 867px) {
        padding: 4rem 0;
      }

      .dots {
        position: absolute;
        z-index: -1;

        @media (max-width: 1499px) {
          display: none;
        }
      }
    }
    p {
      text-align: justify;
    }
    .linkContainer * {
      display: inline;
    }
    a {
      text-decoration: underline;
      color: black;
    }

    .dots-decorator {
      .dots {
        &-t1 {
          left: 20px;
          top: 20px;
        }

        &-t2 {
          bottom: 20px;
          left: 20px;
        }

        &-t3 {
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }
      }
    }
    .imgRow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -15px;
      margin-top: 50px;
      @media (max-width: 500px) {
        margin-top: 0px;
      }
      .imgContainer {
        width: 250px;
        @media (max-width: 500px) {
          margin-top: 50px;
        }
      }
    }
    img {
      width: 100px;
      height: 100px;
    }

    //.row{
    //   display: flex;
    //   justify-content: space-between;
    //}
    .col {
      @media (max-width: 991px) {
        &:first-child {
          order: 1;
          margin-top: 40px;
        }
      }
    }
  `,
  BeInvolved: styled(DefaultSection)`
    p {
      text-align: justify;
    }
    .linkContainer * {
      display: inline;
    }
    a {
      text-decoration: underline;
      color: black;
    }

    .dots-decorator {
      .dots {
        &-t1 {
          left: 20px;
          top: 20px;
        }

        &-t2 {
          bottom: 20px;
          left: 20px;
        }

        &-t3 {
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }
      }
    }
    .imgRow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -15px;
      margin-top: 50px;
      @media (max-width: 500px) {
        margin-top: 0px;
      }
      .imgContainer {
        width: 250px;
        @media (max-width: 500px) {
          margin-top: 50px;
        }
        img {
          width: 100%;
        }
      }
    }
    //костыль
    .ourLogo {
      height: 100px;
    }

    .imgLogo {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem 5rem;
      padding-left: 4rem;

      + .item {
        img {
          max-width: 320px;
        }
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
      }
    }

    //.row{
    //   display: flex;
    //   justify-content: space-between;
    //}
    .col {
      @media (max-width: 991px) {
        &:first-child {
          order: 1;
          margin-top: 40px;
        }
      }
    }
  `,
  Line: styled.section`
    background: ${mainBGColor};
    padding: 70px 0;

    @media (max-width: 991px) {
      padding: 40px 0;
    }

    @media (max-width: 767px) {
      padding: 30px 0;
    }

    > div {
      font-weight: 400;
      font-size: 40px;
      color: white;
      line-height: 140%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-family: "desonanz";

      text-align: center;

      @media (max-width: 965px) {
        padding: 4rem 8rem;
        max-width: 100%;
      }

      @media (max-width: 767px) {
        font-size: 21px;
      }
    }
  `,
  MapArea: styled(DefaultSection)`
    .linkContainer * {
      display: inline;
      text-align: justify;
    }
    p {
      text-align: justify;
    }
    a {
      text-decoration: underline;
      color: black;
    }
    .dots-decorator {
      .dots {
        &-t1 {
          left: 20px;
          bottom: 20px;
        }

        &-t2 {
          top: 20px;
          right: 20px;
        }
      }
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8rem;

      @media (max-width: 990px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .imgContainer {
      flex: 1 1;
      display: flex;
      @media (max-width: 990px) {
        padding: 0 0 10px 0;
      }
      img {
        max-width: 100%;
        object-fit: cover;
      }
    }

    button {
      margin-top: 20px;

      @media (max-width: 767px) {
        margin-top: 10px;
      }
    }
  `,
  Triple: styled.section`
    background: ${mainBGColor};
    padding: 108px 0;

    .linkContainer * {
      display: inline;
    }

    a {
      text-decoration: underline;
      color: black;
    }

    @media (max-width: 767px) {
      padding: 60px 0;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;

      margin: 0 0 50px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 30px;
      }
    }

    .row {
      margin: 0 -15px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;

      @media (max-width: 765px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .col {
      width: 100%;
      padding: 3.4rem 2.6rem;
    }

    img {
      margin: 0 auto 30px;
      height: 18rem;
      min-height: 18rem;
      object-fit: cover;
      max-width: 100%;
      display: block;
    }

    .flexCenter {
      display: flex;
      justify-content: center;
    }

    .flagHolder {
      position: relative;

      .flag {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      .hidden {
        opacity: 0;
      }
    }

    .title {
      font-weight: bold;
      font-size: 2.8rem;
      margin-bottom: 1rem;
      line-height: 1.4;
      color: white;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 21px;
      }
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 1.3;
      color: ${bgColorText};
      margin: 0;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  `,
  School: styled(DefaultSection)`
    .linkContainer * {
      display: inline;
    }
    a {
      text-decoration: underline;
      color: black;
    }
    p {
      text-align: left;
    }
    .center {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .container {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h2 {
      font-size: 32px;
    }
    ol {
      color: black;
      color: ${mainBGColor};
      font-weight: bold;
    }
    ul {
      color: ${mainBGColor};
    }
    .txt {
      color: black;
      font-weight: 500;
      font-size: 21px;
      margin: 0 0 30px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }
    .dots-decorator {
      .dots {
        &-t1 {
          left: 20px;
          top: 20px;
        }

        &-t2 {
          bottom: 20px;
          right: 20px;
        }
      }
    }

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      margin: 0 0 24px;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 16px;
      }
    }
    .titleBlack {
      color: black;
    }
  `,
  WorldLine: styled.section`
    * {
      color: white;
    }

    .map {
      padding: 8rem 0;

      @media (max-width: 767px) {
        padding: 60px 0;
      }
    }
    .indent {
      display: block;
      width: 100%;
      height: 100px;
      background: white;
    }

    .title {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      margin: 0 0 24px;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 16px;
      }
    }
    .titleBlack {
      color: black;
    }

    .txt {
      font-weight: 500;
      font-size: 21px;
      margin: 0 0 30px;
      color: rgba(255, 255, 255, 0.9);
      text-align: center;

      @media (max-width: 767px) {
        font-size: 16px;
        margin: 0 0 20px;
      }
    }

    .btn-row {
      display: flex;
      justify-content: center;
    }

    .alliance-logo {
      width: 300px;
      max-width: 100%;
      margin: 0 auto;

      img {
        max-width: 100%;
      }
    }

    form {
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 -15px;
      }

      .col {
        width: 360px;
        max-width: 100%;
        padding: 0 15px;

        @media (max-width: 767px) {
          width: 100%;

          & + .col {
            margin-top: 24px;
          }
        }
      }

      .checkbox {
        width: 100%;
        max-width: 720px;
        padding: 0 15px;
      }

      .form-group + .form-group {
        margin-top: 30px;

        @media (max-width: 767px) {
          margin-top: 24px;
        }
      }

      .btn-row {
        margin-top: 30px;

        @media (max-width: 767px) {
          button {
            height: 50px;
            width: 100%;
          }
        }
      }
    }
  `,
  Games: styled.section`
    padding: 100px 0;
    position: relative;

    .dots-decor {
      position: absolute;
      right: 0;
      display: block;
      z-index: 9;
      width: 40rem;
      height: 60rem;
      object-fit: cover;
      background-size: contain;
    }

    .dots {
      position: absolute;
      bottom: -36rem;
      left: 0;
      display: block;
      z-index: 0;
      width: 20rem;
      height: 100%;
      background: url("../../img/circle.png") left center no-repeat;
      background-size: contain;
    }

    @media (max-width: 767px) {
      padding: 60px 0;
    }
    .mainUpperContainer {
      margin-top: 4rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      align-items: center;

      @media (max-width: 765px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .rowContainer {
        padding: 2.5rem 5.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:nth-child(2),
        &:nth-child(3) {
          .container {
            .imgContainer {
              img {
                max-width: 90%;
                margin: 0 auto;
                display: block;
                object-fit: contain;
              }
            }
          }
        }

        .small-logo {
          img {
            max-width: 100px;
          }
        }

        @media (max-width: 867px) {
          padding: 2.5rem 2.4rem;
        }
      }
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .containerTitle {
        width: 100%;
        font-size: 2rem;
        font-weight: 500;
      }
    }
    .mainLowContainer {
      margin-top: 50px;

      .textContainer {
        margin-top: -15px;
        color: black;
        width: 50%;
        @media (max-width: 990px) {
          width: 90%;
        }
      }
      .mainImgContainer {
        margin-top: -15px;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 850px) {
          flex-direction: row;
        }
        @media (max-width: 420px) {
          flex-direction: column;
          align-items: center;
          div:first-child {
            margin-top: 0px;
          }
          div {
            margin-top: 50px;
          }
        }
      }
    }
    .flexContainer {
      display: flex;
      justify-content: center;
      @media (max-width: 850px) {
        flex-direction: column;
      }
    }
    .title {
      font-size: 16px;
      margin: 0 0 20px;
      color: #000;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 20px;
      }
    }
    h2 {
      font-weight: bold;
      font-size: 36px;
      color: #000;
      margin: 0 0 40px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 30px;
      }
    }

    .row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -15px;
    }
    .imgContainer {
      height: 100px;

      img {
        height: 100%;
      }
    }
    .col {
      display: flex;
      align-items: center;

      .imgGames {
        width: 100%;
      }
      width: 20%;
      padding: 0 15px;

      @media (max-width: 991px) {
        width: 33.3333%;
      }

      @media (max-width: 767px) {
        width: 50%;
      }
    }
  `,
  Cards: styled.section`
    background: ${bgColorLite};
    padding: 80px 0;

    @media (max-width: 767px) {
      padding: 60px 0;
    }

    .soon {
      background: ${BTNRegistrationBorder};
      font-size: 16px;
      font-weight: bold;
      padding: 6px 12px;
      border-radius: 3px;
      color: #fff;
      margin: 0 auto 10px;
      width: 140px;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      color: #000;
      margin: 0 0 30px;
      display: flex;
      justify-content: center;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 21px;
        margin: 0 0 30px;
      }
    }

    .sub {
      font-weight: 500;
      font-size: 21px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);

      @media (max-width: 767px) {
        font-size: 16px;

        br {
          display: none;
        }
      }
    }

    .main {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
    }

    .item {
      width: 50%;
      height: 380px;
      position: relative;
      overflow: hidden;

      @media (max-width: 991px) {
        height: 300px;
      }

      @media (max-width: 767px) {
        height: 250px;
        width: 100%;
      }

      &:hover {
        .content {
          bottom: 0;
        }
      }
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &-01 {
        background: #fff url(${bonus01}) no-repeat center center;
        background-size: 75%;
      }

      &-02 {
        width: 50%;
        right: auto;
        background: #fff url(${bonus02}) no-repeat center center;
        background-size: contain;
      }

      &-03 {
        width: 50%;
        left: 50%;
        background: #fff url(${bonus03}) no-repeat center center;
        background-size: contain;
      }

      &-04 {
        background: #fff url(${bonus04}) no-repeat center center;
        background-size: cover;
      }

      &-05 {
        background: #fff url(${bonus05}) no-repeat center center;
        background-size: cover;
      }
    }

    .content {
      padding: 30px 30px 10px;
      position: absolute;
      bottom: -70px;
      left: 0;
      right: 0;
      z-index: 2;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0.5) 45%,
        rgba(0, 0, 0, 0) 100%
      );
      transition: all 0.3s ease;

      @media (max-width: 1199px) {
        bottom: -90px;
      }

      @media (max-width: 767px) {
        bottom: -110px;
      }

      .title {
        font-weight: 700;
        font-size: 21px;
        margin: 0 0 20px;
      }
    }

    .btn-row {
      margin: 30px 0 0;
      display: flex;
      justify-content: center;
    }
  `,
  MapContainer: styled.div`
    height: 100%;

    @media (max-width: 991px) {
      margin-bottom: 40px;
    }

    #map {
      height: 100%;

      @media (max-width: 991px) {
        height: 400px;
      }

      @media (max-width: 767px) {
        height: 300px;
      }
    }
  `,
};
