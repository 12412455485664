import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import logo from "./logo-big.png";
export default function Logo() {
  return (
    <StyledLogo>
      <img src={logo} alt="logo" style={{ width: "300px" }} />
    </StyledLogo>
  );
}

const StyledLogo = styled.div`
  width: max-content;
  margin: 0 auto;

  @media (max-width: 350px) {
    width: 60%;

    & > img {
      width: 100%;
    }
  }
`;
