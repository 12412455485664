import React from "react";
import placeHolder from "./img/logo-big.png";
import styles from "./styles.module.scss";
export default function Card({
  id,
  name,
  registrationEndedAt,
  registrationStartedAt,
  slug,
  logo,
  endedAt,
  startedAt,
  game,
}) {
  return (
    <div
      className={styles.card}
      onClick={() =>
        window.open(`https://bigbang.passport.gg/eventPage/${slug}`, "_blank")
      }
    >
      <img src={logo ? logo : placeHolder} alt={name} className={styles.img} />
      <div className={styles.description}>
        <div className={styles.name}>{name}</div>
        {/* <div className={styles.decoration}></div> */}
        {/* <div className={styles.date}>
          date {startedAt} - {endedAt}
        </div> */}
      </div>
    </div>
  );
}
