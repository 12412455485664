import styled from "styled-components";

export const Styled = {
    Message: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        button {
            margin-top: 10px;
        }
    `
}