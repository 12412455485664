import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { Container } from "../UI";
import store from "../../redux/store";
import { showAuthModal, showRegisterModal } from "../../redux/actions/auth";
import { RootState } from "../../redux/store";
import { ROUTER } from "../../config";
import { Styled } from "./style";
import LangSwitcher from "../lang-switcher";

import logoLG from "../../img/big-logo.png";
import styles from "./styles.module.scss";
import classNames from "classnames";

export default function PublicHeader() {
  const { pathname } = useLocation();

  const isUserOnline = useSelector<RootState>((state) => state.userOnline);
  const history = useHistory();
  const toHomepage = (e: React.FormEvent) => {
    e.preventDefault();
    //history.push(isUserOnline ? '/ratings' : ROUTER.homepage)
  };
  /* const goToUrl = (url: 'String') => {
      history.push(url);
   } */

  return (
    <Styled.Header>
      <Container>
        <div className="top">
          <a
            target="_blank"
            href="https://asia.passport.gg"
            className="title" /* onClick={e => toHomepage(e)} */
          >
            <div className="logo logo-sm">
              <img src={logoLG} alt="passport.gg" />
            </div>
            <div className="logo logo-lg">
              <img src={logoLG} alt="passport.gg" />
            </div>
          </a>
          <Styled.FlexGroup>
            {/*<LangSwitcher />*/}

            <Styled.Button
              className="login"
              onClick={() =>
                (window.location.href = "https://bigbang.passport.gg/")
              }
            >
              <FormattedMessage id="global.buttons.login" />
            </Styled.Button>
          </Styled.FlexGroup>
        </div>
        <div className={classNames("bottom", styles.bottom)}>
          <div className="linkContainer" style={{ color: "white" }}>
            <FormattedMessage id="publicHeader.h1.first" tagName="h1" />
            <a href={`/prize_pool_AOCH2022`} style={{ color: "white" }}>
              <FormattedMessage id="publicHeader.h1.link" tagName="h1" />
            </a>
            <FormattedMessage id="publicHeader.h1.last" tagName="h1" />
          </div>
          <div className="linkContainer" style={{ color: "white" }}>
            <FormattedMessage id="publicHeader.subtitle1" tagName="div" />
          </div>
          <div>
            <Styled.ButtonGradient
              style={{ marginRight: "40px", padding: "1rem" }}
              onClick={() => {
                window.location.href = "https://bigbang.passport.gg/";
              }}
            >
              COMPETITORS LOGIN
            </Styled.ButtonGradient>
            <Styled.ButtonGradient
              onClick={() => {
                window.location.href = "https://bigbang.passport.gg/watch";
              }}
            >
              FANS AREA
            </Styled.ButtonGradient>
          </div>
        </div>
      </Container>
    </Styled.Header>
  );
}
