import React, { useState, useEffect, useCallback } from "react";
import Card from "./card";
import { VariableSizeList } from "react-window";

import styles from "./styles.module.scss";

export default function TournamentsList({ communityTournaments }) {
  const [windowWidth, setWindowWidth] = useState();
  console.log(communityTournaments);
  const Row = useCallback(
    ({ index, style }) => {
      const {
        id,
        name,
        registrationEndedAt,
        registrationStartedAt,
        slug,
        media,
        endedAt,
        startedAt,
        thumbnailUrl,
        game,
      } = communityTournaments[index] || {};

      return (
        <div style={style}>
          <Card
            id={id}
            key={id}
            name={name}
            registrationEndedAt={registrationEndedAt}
            registrationStartedAt={registrationStartedAt}
            slug={slug}
            logo={media?.logo}
            endedAt={endedAt}
            startedAt={startedAt}
            thumbnailUrl={thumbnailUrl}
            game={game}
          />
        </div>
      );
    },
    [communityTournaments]
  );
  const windowWidthFunc = () => {
    switch (window.innerWidth) {
      case 1000:
        return setWindowWidth(600);
      case 420:
        return setWindowWidth(360);
      case 375:
        return setWindowWidth(330);
      default:
        return 600;
    }
  };
  windowWidthFunc();
  const getItemSize = () => 126;
  return (
    <div className={styles.main}>
      <div className={styles.cardCountainer}>
        <VariableSizeList
          height={800}
          width={windowWidth}
          itemSize={getItemSize}
          itemCount={communityTournaments?.length}
        >
          {Row}
        </VariableSizeList>
      </div>
    </div>
  );
}
