import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  ButtonRow,
  CheckBox,
  Container,
  Input,
  Select,
  CloseRegModal,
} from "../../components/UI";
import store from "../../redux/store";
import {
  hideAuthModal,
  showAuthBlockedModal,
  showAuthModal,
  showRegisterModal,
  userOnline,
} from "../../redux/actions/auth";
// import {ROUTER} from "../../config";
import { API, API_ROUTER } from "../../api";
import TournamentsList from "../../components/tournaments";

import { toast } from "react-toastify";
import Flag from "react-world-flags";
import { getUserData } from "../../redux/actions/user";
import { Box, Button, LinearProgress } from "@material-ui/core";
import {
  getCookiesPolicyLink,
  getPrivacyPolicyLink,
  getTermsLink,
  getUrlParams,
  getValueFromSelect,
  isEmailValid,
  isFieldEmpty,
  isPasswordValid,
  isSelectEmpty,
} from "../../helpers";
import PublicHeader from "../../components/header-public";
import { Styled } from "./style";

import Countries from "./countriesList";
// images
import picBeInvolved from "./img/be-involved.png";
import decorationDots from "./img/dots";
import rotatedDots from "./img/dotsRotated";
import titleArrows from "./img/titleArrows";
//flags
// import countryFlag from "../../img/img.png";

// import trImage02 from "./img/triple-02.png";
// import trImage03 from "./img/triple-03.png";
import countryFlag from "./img/towns.jpg";
import logoLG from "../../img/big-logo.png";

import trImage02 from "./img/competition.jpg";
import trImage03 from "./img/sky.jpg";

import schoolImage from "./img/school.png";
import communityImage from "./img/Frame-17.jpg";
import allianceLogo from "../../img/logo-big.png";
import fc from "../../img/fc.png";
import inst from "../../img/inst.png";
import tw from "../../img/twitter.png";
import dots2 from "../../img/circle2.png";

import styles from "./styles.module.scss";
import circle from "../../img/circle.png";

// games pics
import gFortnite from "./img/games/game-fortnite.png";
import gDota from "./img/games/game-dota.png";
import gConstellation from "./img/games/Constellation.png";
import gLESF from "./img/games/LESF.png";
import gNHA from "./img/games/NHA.png";
import bigBang from "../../img/BBMV.png";
import iesfColored from "./img/iesfColored.png";

import gPassport from "./img/games/passport.png";
import gClashC from "./img/games/clashClans.jpg";

// import gClashC from "./img/games/Clash-of-Clans.png";
import gDota2 from "./img/games/Dota2.png";
import gCricket from "../../img/real.jpg";
import gPUBG from "./img/games/PUBG.png";
import eFootbal from "../../img/E-football.png";
import eFootbalSquare from "../../img/e-football.jpg";
import gCs from "./img/games/game-csgo.png";
import gOverwatch from "./img/games/game-overwatch.png";
import gFifa from "./img/games/games-fifa.png";
import gLeague from "./img/games/games-league-legends.png";
import gCOD from "./img/games/games-call-of-duty.png";
import gValor from "./img/games/games-arenavalor.png";
import gRBS from "./img/games/games-rainbowsix.png";
import gHS from "./img/games/games-heartstone.png";
import gSC2 from "./img/games/games-startcraft.png";
import gRocket from "./img/games/games-rocket-league.png";
import gApex from "./img/games/games-apex-legend.png";
import gPubg from "./img/games/game-battleground.png";
import gMTG from "./img/games/games-mtg-arena-1.png";
import gMap from "./img/map_east.jpg";
import videoBigBang from "./video/IEFS_ASIA OPEN-Horizontal.mp4";
import modal from "./modal.module.scss";
//карты гугл
/* import { Map } from '../../components/Map'; */
/* import { API_KEY } from '../../App'; */
//переменная окружения из файла .env
function Homepage() {
  // state = {
  //   dataLoaded: false,
  //   googleMapsLoaded: false,
  //   userLocation: true,
  //   values: {
  //     firstName: "",
  //     username: "",
  //     password: "",
  //     country: [],
  //   },
  //   errors: {
  //     firstName: "",
  //     username: "",
  //     password: "",
  //     country: "",
  //     gdpr: "",
  //   },
  //   gdprChecked: false,
  //   marketingChecked: false,
  //   countryName: "",
  //   countryFlag: countryFlag,
  //   pathname: "",
  //   URLpathname: "",
  //   setCommunityTournaments: {},
  // };

  // static getDerivedStateFromProps(props, state) {
  //   const { userOnline, history } = props;
  //   const { dataLoaded } = state;
  //   if (!dataLoaded) {
  //     state.dataLoaded = true;
  //     return state;
  //   }
  //   return null;
  // }
  const [communityTournaments, setCommunityTournaments] = useState({});
  useEffect(() => {
    let isMounted = true;
    getTounrmanetsData();
    return () => {
      isMounted = false;
    };
  }, []);
  const getTounrmanetsData = async () => {
    await API.request({
      ...API_ROUTER.tournaments.getTournamentsCommunity,
      pathKeys: {
        communityId: "co-KBHXRRSMP5PEK06A7YCK7JX",
      },
    })
      .then((res) => setCommunityTournaments(res?.events))
      .catch((err) => console.log(err));
  };
  const onInputChange = (e) => {
    const { values } = this.state;
    values[e.target.name] = e.target.value;

    this.setState({ values });
  };

  const onSelectChange = (value) => {
    const { values } = this.state;
    values.country = value;

    this.setState({ values });
  };

  const onCheckboxChange = (e) => {
    if (e.target.name === "gdpr")
      this.setState({ gdprChecked: !this.state.gdprChecked });

    if (e.target.name === "marketing")
      this.setState({ marketingChecked: !this.state.marketingChecked });
  };

  const signUp = (e) => {
    e.preventDefault();

    const URLParams = getUrlParams();
    const { values, errors, gdprChecked, marketingChecked } = this.state;
    // const {history} = this.props;

    // validate
    errors.firstName = isFieldEmpty(values.firstName);
    errors.username = isEmailValid(values.username);
    errors.password = isPasswordValid(values.password);
    errors.country = isSelectEmpty(values.country);
    gdprChecked ? (errors.gdpr = "") : (errors.gdpr = "This field is required");
    this.setState({ errors });

    // send request if valid
    if (!Object.values(errors).some((value) => value.length)) {
      const params = {
        ...API_ROUTER.auth.registerEvent,
        pathKeys: {
          eventName: "platform-beta",
        },
        data: {
          ...values,
          country: getValueFromSelect(values.country),
          gdpr: gdprChecked,
          marketing: marketingChecked,
          token: "fake",
        },
      };

      // get referral
      if (URLParams.ref) {
        params.data.referral = URLParams.ref;
      }

      API.request(params, true)
        .then(({ token, refreshToken }) => {
          store.dispatch(showAuthBlockedModal());
          store.dispatch(showAuthModal());
        })
        .catch((err) => {
          let errorMessage = null;
          const { errors } = this.state;

          if (err.data && err.data.errors) {
            if (err.data.errors.username) {
              errorMessage = `The username ${this.state.values.username} already exists. Please sign in!`;
              errors.username = "User already exists";
              store.dispatch(showAuthModal());
            }
          }

          this.setState({ errors });
          if (errorMessage) {
            toast.error(errorMessage);
          }
        });
    }
  };

  //////////////////////////////////////////

  // const { userLocation, values, errors, setCommunityTournaments } =
  //   this.state;

  // console.log(setCommunityTournaments);

  return (
    <>
      <PublicHeader />
      <CloseRegModal
        className={modal.modal__wrapper}
        isTransparent={true}
        open={false}
      >
        <div className={modal.modal}>
          <img className={modal.modal__image} src={logoLG} alt="modal__image" />

          <div className={modal.modal__title}>
            <p className={modal.modal__title__header}>Dear Friends!</p>
            <p className={modal.modal__title__header}>
              The registration for the competition is closed! If you have
              registered, you will recieve soon an email with next steps.
              Matches schedule will be soon available here, as well as in the
              competition system, where you can login with button below.
            </p>
            <p className={modal.modal__title__header}>GL HF</p>
            <div className={modal.modal__title__buttons}>
              <div className={modal.button}>
                <div
                  className={modal.buttonLogin}
                  onClick={() =>
                    (window.location.href = "https://bigbang.passport.gg/")
                  }
                >
                  <FormattedMessage id="global.buttons.login" />
                </div>
              </div>

              <div className={modal.button}>
                <div
                  className={modal.buttonLogin}
                  onClick={() =>
                    (window.location.href =
                      "https://about.passport.gg/gamersupport")
                  }
                >
                  HELP
                </div>
              </div>
            </div>
          </div>
        </div>
      </CloseRegModal>
      <Styled.BeInvolved>
        <div className="dots-decorator">
          <Container>
            <h3 className={styles.title} style={{ maxWidth: "100%" }}>
              Players from 40+ COUNTRIES. 5 GAMES. ONE DREAM
            </h3>
            <div className="row">
              <div className="col">
                {/* <div className={styles.description}>
                    <FormattedMessage id="homepage.beInvolved.p1" />
                  </div> */}
                {/* <a className={styles.link} href={`/schedule_AOCH2022`}>
                    <FormattedMessage id="homepage.beInvolved.linkSchedule" />
                  </a> */}
                {/* <ButtonRow direction="left">
                    <Button
                      className={styles.button}
                      id="register"
                      // onClick={() => {
                      //   store.dispatch(showAuthModal());
                      //   store.dispatch(showRegisterModal());
                      // }}
                    >
                      <FormattedMessage id="global.buttons.signUp" />
                    </Button>
                  </ButtonRow> */}
                <video
                  className={styles.videoContainer}
                  src={videoBigBang}
                  width="300"
                  height="300"
                  controls="controls"
                  autoPlay={true}
                  muted
                  loop
                />
              </div>
              <div>
                <div className="imgLogo">
                  <div className="item">
                    <a target="_blank" href="https://clashofclans.com">
                      <img src={gClashC} alt="be involved" />
                    </a>
                  </div>
                  <div className="item">
                    <a target="_blank" href="https://www.dota2.com/home">
                      <img
                        style={{ padding: "40px", marginLeft: "-25px" }}
                        src={gDota2}
                        alt="be involved"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a target="_blank" href="https://www.nautilusmobile.com/">
                      <img src={gCricket} className="imgB" alt="be involved" />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.pubgmobile.com"
                    >
                      <img
                        onClick={() =>
                          (window.location.href = "https://www.pubgmobile.com")
                        }
                        style={{ width: "80%" }}
                        src={gPUBG}
                        className="imgPUBG"
                        alt="be involved"
                      />
                    </a>
                  </div>
                </div>
                <div className="item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.konami.com/efootball/en-us/"
                  >
                    <img
                      onClick={() =>
                        (window.location.href =
                          "https://www.konami.com/efootball/en-us/")
                      }
                      src={eFootbal}
                      className="imgPUBG"
                      alt="eFootbal"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Styled.BeInvolved>

      <Styled.Line>
        <div>Event schedule and matches</div>
      </Styled.Line>
      <Styled.Tournaments>
        <div className="dots-decorator" style={{ padding: " 10rem 0 0 0" }}>
          <Container>
            <div className="row">
              <div className="col">
                <div>
                  <h1
                    className={styles.description}
                    style={{ maxWidth: "none", fontSize: "3rem" }}
                  >
                    Access the full schedule
                  </h1>
                  <TournamentsList
                    communityTournaments={communityTournaments}
                  ></TournamentsList>
                </div>
              </div>
              <div className="col">
                <div>
                  <h1
                    className={styles.description}
                    style={{ maxWidth: "none", fontSize: "3rem" }}
                  >
                    General information
                  </h1>

                  {/* <h3 className={styles.title}>
                    <FormattedMessage id="homepage.rockArea.landing" />
                  </h3> */}
                  <div
                    className={styles.description}
                    style={{ maxWidth: "none" }}
                  >
                    Get ready for an electrifying event that will have you on
                    the edge of your seat. The Big Bang IeSF Asia Open is here
                    and it’s just massive!
                  </div>

                  <div
                    className={styles.description}
                    style={{ maxWidth: "none" }}
                  >
                    With participants from 40+ countries, this is an esports
                    event like no other. Gamers will compete across 5 titles in
                    3 different layers, showcasing their incredible skill and
                    talent.
                  </div>

                  <div
                    className={styles.description}
                    style={{ maxWidth: "none" }}
                  >
                    <h3 style={{ textAlign: "center" }}>
                      The general schedule of the event per
                    </h3>
                  </div>
                  <div className={styles.tableContainer}>
                    <table border="2" cellpadding="10" cellspacing="2">
                      <colgroup>
                        <col width="33.3%" />
                        <col width="33.3%" />
                        <col width="33.3%" />
                      </colgroup>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">First matches</th>
                        <th scope="col">Finals</th>
                      </tr>
                      <tr>
                        <td>Clash of Clans</td>
                        <td>26.02.2023</td>
                        <td>05.03.2023</td>
                      </tr>
                      <tr>
                        <td>Real Cricket</td>
                        <td>27.02.2023</td>
                        <td>12.03.2023</td>
                      </tr>
                      <tr>
                        <td>Dota 2</td>
                        <td>13.03.2023</td>
                        <td>19.03.2023</td>
                      </tr>{" "}
                      <tr>
                        <td>PUBG Mobile</td>
                        <td>16.03.2023</td>
                        <td>26.03.2023</td>
                      </tr>{" "}
                      <tr>
                        <td>eFootball</td>
                        <td>18.03.2023</td>
                        <td>02.04.2023</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Styled.Tournaments>
      <Styled.Line>
        <FormattedMessage
          id="homepage.organisersPartners.title"
          tagName="div"
        />
      </Styled.Line>
      <Styled.Games>
        <Container>
          <div className="mainUpperContainer">
            <div className="rowContainer">
              <div className="containerTitle">
                <div className="title">
                  <FormattedMessage id="homepage.organisersPartners.eventOrganisers" />
                </div>
              </div>
              <div className="container">
                <a
                  target="_blank"
                  href="https://www.facebook.com/BigBangSports.gg"
                >
                  <div className="imgContainer">
                    <img src={bigBang} alt="NHA" />
                  </div>
                </a>
              </div>
            </div>

            <div className="rowContainer small-logo">
              <div className="containerTitle">
                <div className="title">
                  <FormattedMessage id="homepage.organisersPartners.eventLicensed" />
                </div>
              </div>
              <div className="container">
                <a target="_blank" href="https://iesf.org">
                  <div className="imgContainer">
                    <img src={iesfColored} alt="LESF" />
                  </div>
                </a>
              </div>
            </div>

            <div className="rowContainer">
              <div className="containerTitle">
                <div className="title">
                  <FormattedMessage id="homepage.organisersPartners.technicalPartner" />
                </div>
              </div>
              <div className="container">
                <div className="imgContainer ">
                  <a target="_blank" href="https://about.passport.gg/">
                    <img src={gPassport} alt="Passport" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <img src={circle} className="dots" />
      </Styled.Games>
      {/* <Styled.Line>
          <FormattedMessage id="homepage.blueLine2" tagName="div" />
        </Styled.Line>
        <Styled.School>
          <div className="dots-decorator">
            <Container>
              <div className="row">
                <div className="col">
                  <h3 className={styles.titleFullLeft}>
                    <FormattedMessage id="homepage.dedicatedRatings.title1" />
                  </h3>

                  <div className={styles.text}>
                    <div className={styles.text}>
                      <FormattedMessage id="homepage.dedicatedRatings.title2" />
                    </div>
                    <ul>
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p3"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p2"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p22"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p5"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p4"
                        tagName="li"
                      />
                    </ul>
                  </div>

                  <div className={styles.text}>
                    <p>
                      The event includes 3{" "}
                      <a href="/rules_AOCH2022#levels">levels</a> of
                      competition, which allow any gamer to get a chance to
                      become a champion. Gather your team and choose one of the
                      levels:
                    </p>
                    <ul>
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p6"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p7"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p8"
                        tagName="li"
                      />
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <img
                    src={communityImage}
                    alt="Special ratings: be the best in your community!"
                  />
                </div>
              </div>

              <div className="center">
                <Button
                  id="register"
                  className={styles.button}
                  // onClick={() => {
                  //   store.dispatch(showRegisterModal());
                  //   store.dispatch(showAuthModal());
                  // }}
                >
                  <FormattedMessage id="global.buttons.signUp_now" />
                </Button>
              </div>
            </Container>
          </div>
        </Styled.School>

        <Styled.WorldLine>
          <div className="map">
            <Container>
              <div className={styles.row}>
                <div className={styles.colCenter}>
                  <img
                    className={styles.logo}
                    src={allianceLogo}
                    alt="e-sport"
                  />
                  <div className={styles.rowSoc}>
                    <a
                      href="https://www.facebook.com/BigBangSports.gg"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={fc} />
                    </a>
                    <a
                      href="https://www.instagram.com/bigbangsports.gg/"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={inst} />
                    </a>

                    <a
                      href="https://twitter.com/BigBangSports_"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={tw} />
                    </a>
                  </div>
                </div>
                <div className={styles.col}>
                  <h3 className={styles.titleFullLeft}>
                    <span className={styles.whiteText}>
                      <FormattedMessage id="homepage.blueLine4.title" />
                    </span>
                  </h3>
                  <div className={styles.text}>
                    <span className={styles.whiteText}>
                      <FormattedMessage id="homepage.blueLine4.message" />
                    </span>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.gradientButton}
                      onClick={() =>
                        (window.location.href = "https://bigbang.passport.gg/")
                      }
                    >
                      <FormattedMessage id="homepage.blueLine4.button" />
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Styled.WorldLine>
        <Styled.Games>
          <Container>
            <div className={styles.titleFull}>
              <FormattedMessage id="homepage.topGames" />
            </div>
            <div className={styles.imgGrid}>
              <div className={styles.item}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.konami.com/efootball/en-us/"
                >
                  <img
                    src={eFootbalSquare}
                    alt="eFootbal"
                    className="imgGames"
                    style={{ padding: "35px" }}
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://clashofclans.com">
                  <img
                    src={gClashC}
                    alt="Clash-of-Clans"
                    className="imgGames"
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://www.dota2.com/home">
                  <img
                    src={gDota2}
                    alt="clashroyale_logo"
                    className="imgGames"
                    style={{ padding: "50px" }}
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://www.nautilusmobile.com/">
                  <img src={gCricket} alt="Critical Ops" className="imgGames" />
                </a>
              </div>
              <div className={styles.item}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pubgmobile.com"
                >
                  <img
                    onClick={() =>
                      (window.location.href = "https://www.pubgmobile.com")
                    }
                    src={gPUBG}
                    alt="PUBG"
                    className="imgGames "
                    style={{ padding: "30px" }}
                  />
                </a>
              </div>
            </div>
          </Container>
          <img className="dots-decor" src={dots2} alt="bg" />
        </Styled.Games> */}
      <Styled.WorldLine>
        <div className="map">
          {/* <Container> */}
          {/* <div className={styles.titleFull}>
                <span className={styles.whiteText}>
                  <FormattedMessage id="homepage.register.title1" />
                  <a href={`/prize_pool_AOCH2022/ `}>
                    <FormattedMessage id="homepage.register.title.link" />
                  </a>
                  <FormattedMessage id="homepage.register.title2" />
                </span>
              </div> */}
          {/* 
              <div className={styles.textCenter}>
                <FormattedMessage id="homepage.register.description" />
                <a href={`/rules_AOCH2022/#country`}>
                  <FormattedMessage id="homepage.register.description.link" />
                </a>
              </div> */}
          {/* <form onSubmit={e => this.signUp(e)}>
                        <div className="form-group">
                           <div className="col">
                              <Input
                                 onChange={this.onInputChange}
                                 name="firstName"
                                 variant="homepage"
                                 placeholder={intl.formatMessage({ id: "global.forms.placeholders.firstName" })}
                                 error={errors.firstName}
                                 value={values.firstName} />
                           </div>
                           <div className="col">
                              <Input
                                 onChange={this.onInputChange}
                                 name="username"
                                 variant="homepage"
                                 type="email"
                                 placeholder={intl.formatMessage({ id: "global.forms.placeholders.email" })}
                                 error={errors.username}
                                 value={values.username} />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="col">
                              <Select
                                 options={countriesList}
                                 variant="homepage"
                                 values={values.country}
                                 error={errors.country}
                                 placeholder={intl.formatMessage({ id: "global.forms.placeholders.select" })}
                                 onChange={value => this.onSelectChange(value)} />
                           </div>
                           <div className="col">
                              <Input
                                 onChange={this.onInputChange}
                                 variant="homepage"
                                 name="password"
                                 type="password"
                                 placeholder={intl.formatMessage({ id: "global.forms.placeholders.password" })}
                                 error={errors.password}
                                 value={values.password} />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="checkbox">
                              <CheckBox
                                 name="gdpr"
                                 error={errors.gdpr}
                                 onChange={this.onCheckboxChange}
                                 checked={this.state.gdprChecked}>
                                 <FormattedMessage
                                    id="authModal.signUp.check1.full"
                                    values={{
                                       linkTerms: (
                                          <a href={getTermsLink(this.props.interfaceLang)} target="_blank" rel="noopener noreferrer">
                                             <FormattedMessage id="authModal.signUp.check1.linkTerms" />
                                          </a>
                                       ),
                                       linkPrivacy: (
                                          <a href={getPrivacyPolicyLink(this.props.interfaceLang)} target="_blank" rel="noopener noreferrer">
                                             <FormattedMessage id="authModal.signUp.check1.linkPrivacy" />
                                          </a>
                                       ),
                                       linkCookie: (
                                          <a href={getCookiesPolicyLink(this.props.interfaceLang)} target="_blank" rel="noopener noreferrer">
                                             <FormattedMessage id="authModal.signUp.check1.linkCookie" />
                                          </a>
                                       )
                                    }}
                                 />
                              </CheckBox>
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="checkbox">
                              <CheckBox
                                 name="marketing"
                                 onChange={this.onCheckboxChange}
                                 checked={this.state.marketingChecked}>
                                 <FormattedMessage id="authModal.signUp.check2" />
                              </CheckBox>
                           </div>
                        </div> */}
          {/* <div className={styles.buttonContainerCenter}>
                <button
                  type="submit"
                  id="register"
                  className={styles.gradientButton}
                  onClick={() => {
                    store.dispatch(showRegisterModal());
                    store.dispatch(showAuthModal());
                  }}
                >
                  <FormattedMessage id="global.buttons.signUp_now" />
                </button>
              </div> */}
          {/* </Container> */}
        </div>
      </Styled.WorldLine>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userOnline: state.userOnline,
    countriesList: state.countriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
    runUserOnline: () => dispatch(userOnline()),
    runDispatchHideAuthModal: () => dispatch(hideAuthModal()),
  };
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Homepage))
);
